<template>
    <DnCookiebar
        :has-preferences="false"
        :title="t('cookieConsent.cookiebar.title')"
    >
        <template #content>
            <i18n-t keypath="cookieConsent.cookiebar.text">
                <template #link>
                    <NuxtLink
                        v-if="isDoen"
                        :to="localePath('/privacy-cookiestatement')"
                    >
                        {{ t('cookieConsent.cookiebar.linkLabel') }}
                    </NuxtLink>
                    <NuxtLink
                        v-else-if="isBglf"
                        :to="localePath('/privacy-en-cookie-statement')"
                    >
                        {{ t('cookieConsent.cookiebar.linkLabel') }}
                    </NuxtLink>
                    <a
                        v-else
                        href="https://www.doen.nl/privacy-cookiestatement"
                    >
                        {{ t('cookieConsent.cookiebar.linkLabel') }}
                    </a>
                </template>
            </i18n-t>
        </template>

        <template #decline="{ declineCookies }">
            <BaseButton
                class="cookiebar__actions button button--secondary"
                @click.prevent="declineCookies"
            >
                {{ t('cookieConsent.cookiebar.dismiss') }}
            </BaseButton>
        </template>

        <template #accept="{ acceptAllCookies }">
            <BaseButton
                class="cookiebar__actions button button--secondary"
                @click.prevent="acceptAllCookies"
            >
                {{ t('cookieConsent.cookiebar.accept_all') }}
            </BaseButton>
        </template>
    </DnCookiebar>
</template>

<script setup>
import DnCookiebar from '@digitalnatives/cookie-bar';

const { t } = useI18n();
const localePath = useLocalePath();
const { isDoen, isBglf } = useMultisite();
</script>

<style src="./TheCookiebar.less" lang="less" />
